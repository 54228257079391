import React from "react"
import { StaticQuery, Link, graphql, useStaticQuery } from "gatsby"
import Img from 'gatsby-image'
import { Row, Col, Card } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { 
        faAngleRight
    } from "@fortawesome/pro-solid-svg-icons"

import CrestPlaceholderImg from './CrestPlaceHolderImg'

const getEventPosts = graphql`
query {
  allContentfulEventPost(sort: {fields: [eventDate], order: DESC}) {
    totalCount
    edges {
      node {
        slug
        title
        updatedAt(formatString: "MMMM Do, YYYY")
        childContentfulEventPostPostExcerptTextNode {
          postExcerpt
        }
        image: featuredImage {
          fluid(maxWidth: 600) {
            src
            srcSet
          }
        }
        eventDate(formatString: "MMMM D, YYYY")
      }
    }
  }
}
`

const EventsCards = () => {
  
  return (
    <StaticQuery
      query={ getEventPosts }
      render={ data => {
        
        const posts = data.allContentfulEventPost.edges;

        console.log('posts', posts);

        return (
         posts.map(({ node }) => {
           return (
             <>
             <Row>
               {!node.image ? <Col sm={3}><CrestPlaceholderImg /></Col> : (
               <Col sm={3}>
               <Link to={`/news/${node.slug}`}>
               <img style={{
                   width: '100%'
                 }}
               src={node.image ? node.image.fluid.src : null } alt={node.title} className="rounded"/>
               </Link>
         
           </Col> ) }
           <Col>
           <p className="post-title"><Link to={`/events/${node.slug}`}>{node.title}</Link><br />
           <span className="updated">{node.eventDate}</span></p>
           <p>{node.childContentfulEventPostPostExcerptTextNode.postExcerpt}</p>
           <div className="text-right"><Link to={`/events/${node.slug}`}>Read More <span>
           <FontAwesomeIcon
                     icon={faAngleRight}
                     style={{
                         fontSize: '1rem',
                         marginRight: '5px',
                     }}
                     aria-hidden="true"
                     
                 />
             </span></Link></div>
 
           </Col>
             </Row>
             <hr />
             </>
           )
         })
       )  
       }}
       >
      </StaticQuery>
  )
}

export default EventsCards
