import React from "react"
import { graphql } from "gatsby"
import SEO from '../components/seo'
import Layout from '../components/layout'
import ContentBlock from '../components/ContentBlock/ContentBlock'
import PageTitle from '../components/PageTitle/PageTitle'
import { Container, Row, Col } from 'react-bootstrap'
import EventPreview from "../components/eventPreview"
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import PostLinks from '../components/PostLinks'
import CrestPlaceholderImg from '../components/NewsBlock/CrestPlaceHolderImg'
import EventsPageCards from "../components/EventsBlock/eventPageCards"

const Events = ({ data }) => {

  return (

<Layout>
<SEO title="Past USS IDAHO Events"/>
<Container fluid className="container-fluid-full">
  <PageTitle pageTitle="Past USS IDAHO Events" >
    {/* {page.pageTitle} */}
  </PageTitle>
  <ContentBlock>
    <EventsPageCards />
      
    </ContentBlock>
    </Container>
</Layout>
  )
}


export default Events
